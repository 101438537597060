/* ********** Material Config ********** */

@use '@angular/material' as mat;
@import '@angular/material/theming';
@include mat-core();

$astrea-green: (
  50: #008F86, 100: #008F86, 200: #008F86, 300: #008F86, 400: #008F86, 500: #008F86, 600: #008F86, 700: #008F86, 800: #008F86, 900: #008F86, A100: #008F86, A200: #008F86, A400: #008F86, A700: #008F86,
  contrast: (
    50: #FAFAFA, 100: #FAFAFA, 200: #FAFAFA, 300: #FAFAFA, 400: #FAFAFA, 500: #FAFAFA, 600: #FAFAFA, 700: #FAFAFA, 800: #FAFAFA, 900: #FAFAFA, A100: #FAFAFA, A200: #FAFAFA, A400: #FAFAFA, A700: #FAFAFA,
  )
);

$astrea-primary: mat-palette($astrea-green);
$astrea-accent:  mat-palette($astrea-green);
$astrea-warn:    mat-palette($mat-red);
$astrea-theme:   mat-light-theme($astrea-primary, $astrea-accent, $astrea-warn);
@include all-component-colors($astrea-theme);

$typography: mat.define-typography-config(
    $font-family: var(--typography)
);
$typography-theme: mat.define-light-theme((
    typography: $typography
));
@include mat.all-component-themes($typography-theme);


/* ********** Styles ********** */

@import "./styles/astrea-styles.min";

html, body {
    height: 100%;
}
body {
    margin: 0;
}


// https://cssgradient.io/
.astrea-super-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(160deg, rgba(255,138,158,1) 15%, rgba(145,215,103,1) 50%, rgba(35,187,173,1) 85%);
    opacity: 0.15;
    filter: blur(50px);
}
.dark-mode {
    .astrea-super-background {
        opacity: 0.05;
    }
}

// CLASES PARA TODA LA APP

// MAIN CONTAINER
.container {
    width: 80%;
    max-width: 1536px;
    margin: 0 auto;
}

@media screen and (max-width: 1440px) {
    .container {
        width: 100%;
    }
}

.cdk-overlay-pane {
    max-width: 95vw !important;
}
.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}
.o-ellipsis-wrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
@media (max-width: 960px) {
    .o-ellipsis-wrap {
        white-space: normal;
    }
}
.without-content {
    p {
        color: var(--text-black-white-1);
    }
}

// ESTILOS GENERAL PARA TODOS LOS BOX
.astrea-box {
    background-color: var(--background-white-dark-1);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: 1.5rem;
}
@media (max-width: 960px) {
    .astrea-box {
        overflow-x: scroll;
    }
}
@media (max-width: 576px) {
    .astrea-box {
        padding: 1rem;
    }
}

// FORMS INPUT
.mat-form-field {
    .mat-form-field-wrapper {
        margin: 0px 0px 10px 0px !important;
    }
}

// MENU DROPDOWN
.astrea-date-box {
    background: var(--background-white-dark-1);
    margin-top: 11px;
    height: 64px;

    small {
        color: var(--text-black-white-1);
    }

    .from-to {
        small {
            font-size: 15px;
        }
    }
}
.astrea-date-box:hover {
    border-bottom: 2px solid var(--color-green-1);
}

.astrea-date-dropdown {
    background: #FAFAFA !important;
    padding: 10px !important;
    padding-bottom: 0 !important;
    border-radius: 5px;
    width: 200px;
    box-shadow: 0 3px 6px #00000029;

    p {
        color: #121212;
        font-size: 16px !important;
        margin-bottom: 10px !important;
    }
    mat-form-field {
        .mat-form-field-wrapper {
            margin-bottom: 0 !important;
        }
        .mat-form-field-flex {
            background: #E4E6EB;
        }
    }
}
.dark-mode ~ .cdk-overlay-container .astrea-date-dropdown {
    background-color: #121F3D !important;

    p {
        color: #FAFAFA;
    }
    mat-form-field {
        span, .mat-select-arrow-wrapper {
            color: #FAFAFA;
        }
        .mat-form-field-flex {
            background: #24385B;
        }
    }
}

.astrea-delete {
    border-radius: 5px !important;
    box-shadow: 0 2px 4px #00000029;

    button {
        background: #FAFAFA !important;
        box-shadow: none !important;
    }
}
.dark-mode ~ .cdk-overlay-container .astrea-delete {
    button {
        background: #121F3D !important;
    }
}

// ESTILOS GENERAL PARA TODAS LAS TABLAS
.astrea-table-overflow {
    overflow-y: auto;
    max-height: 60vh;
}
.astrea-table {
    color: var(--text-black-white-1);
    text-align: left;
    width: 100%;

    tr {
        height: 100%;

        &:nth-child(odd) {
            td {
                background-color: var(--background-white-dark-2);
            }
        }
        th {
            background-color: var(--background-white-dark-3);
            font-size: 15px;
            font-weight: 700;
            padding: 1rem;
        }
    }
    tr td {
        padding: .8rem 1rem .8rem 1rem;
        font-size: 12px;
        font-weight: 500;
    }
    tr th, tr td {
        height: 100%;

        &:first-child {
            border-radius: var(--border-radius) 0px 0 var(--border-radius);
        }
        &:last-child {
            border-radius: 0px var(--border-radius) var(--border-radius) 0px;
            padding: 0 1rem;
        }

        .mat-icon-button {
            color: var(--text-green-white-1) !important;
            background: transparent;
            margin-right: .3rem;
            cursor: pointer;

            .material-icons {
                display: none;
                font-size: 26px;
            }
        }
    }
    tr:hover {
        .mat-icon-button {
            .material-icons {
                display: inline !important;
            }
        }
        .mat-icon-button:hover {
            color: var(--text-green-white-2) !important;
        }
    }
    @media (max-width: 576px) {
        tr {
            .mat-icon-button {
                .material-icons {
                    display: inline !important;
                }
            }
        }
    }
}

.cdk-overlay-container {
    .btn-filter {
        color: #FAFAFA !important;
        background-color: #095E60 !important;
        height: 25px !important;
        line-height: 20px !important;
        border-radius: 5px !important;
    }
}

.dark-mode ~ .cdk-overlay-container {
    .btn-filter {
        background-color: #008F86 !important;
    }
}
